/* eslint-disable no-nested-ternary */
import { transparentize, lighten, readableColor } from 'polished';
import styled, { ColorScheme } from 'styled-components';

type TWrapper = {
  color: ColorScheme;
  width?: number | 'full';
  textAlign: 'center' | 'left' | 'right' | 'justify';
};

export const Wrapper = styled.div<TWrapper>`
  display: inline-flex;
  align-self: flex-start;
  text-align: ${({ textAlign }) => textAlign};
  border: 1px solid ${(p) => p.theme.colors[p.color]};
  background-color: ${(p) => transparentize(0.65, p.theme.colors[p.color])};
  color: ${(p) =>
    readableColor(
      lighten(0.2, p.theme.colors[p.color]),
      p.theme.colors.textDark,
      p.theme.colors.textLight
    )};

  border-radius: ${(p) => p.theme.borderRadius};
  font-size: ${(p) => p.theme.font.small};
  line-height: 18px;
  padding: ${(p) => p.theme.spacing.medium} ${(p) => p.theme.spacing.large};
  width: ${(p) =>
    p.width ? (p.width === 'full' ? '100%' : `${p.width * 100}%`) : ''};
`;
