import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${(p) => p.theme.spacing.medium};

  font-size: ${(p) => p.theme.font.small};

  @media (max-width: 768px) {
    font-size: ${(p) => p.theme.font.xSmall};
    padding: ${(p) => p.theme.spacing.medium};
  }
`;

export const WrapperInput = styled.form`
  border-radius: ${(p) => p.theme.borderRadius};
  flex: 1;
  display: flex;

  & > input {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > label {
    padding: 0;
  }

  & > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 40px;
  }
`;

export const WrapperDeliveryType = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.small};

  & svg {
    stroke-width: 0;
  }
`;

export const Option = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  & svg {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const Value = styled.span`
  font-weight: bold;
`;
