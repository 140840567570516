import { FC, useMemo, useState } from 'react';
import { FiLock, FiUnlock } from 'react-icons/fi';

import { IInput } from '@pra-vendas-themes/interfaces/IInput';

import { StyledInput, WrapperInput, WrapperIcon } from './styles';

const Input: FC<IInput> = ({
  placeholder,
  disabled = false,
  color = 'primary',
  name,
  autoFocus = false,
  onChange,
  onBlur,
  value,
  onFocus,
  type,
  maxLength,
  onKeyPress,
}) => {
  const [_type, setType] = useState(type);

  const hasIcon = useMemo(() => {
    return type === 'password';
  }, [type]);

  const handleTogglePassword = (): void => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  return (
    <WrapperInput>
      <StyledInput
        hasIcon={hasIcon}
        autoFocus={autoFocus}
        id={name}
        name={name}
        color={color}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        value={value}
        type={_type}
        maxLength={maxLength}
      />
      {hasIcon && (
        <WrapperIcon
          data-title={_type === 'password' ? 'Mostrar senha' : 'Esconder senha'}
          onClick={handleTogglePassword}
        >
          {_type === 'password' ? <FiLock /> : <FiUnlock />}
        </WrapperIcon>
      )}
    </WrapperInput>
  );
};

export default Input;
