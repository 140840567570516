import { IButton } from '@pra-vendas-themes/interfaces/IButton';
import { Loading } from '@pra-vendas-themes/default/components/Loading';

import { WithChildren } from 'types/WithChildren';

import { StyledButton } from './styles';

const Button: WithChildren<IButton> = ({
  children,
  size = 'medium',
  color = 'primary',
  font = 'medium',
  lineHeight,
  type,
  width,
  transparent,
  onClick,
  isLoading,
  disabled,
  variant = 'default',
}) => {
  return (
    <StyledButton
      disabled={isLoading || disabled}
      transparent={transparent}
      size={size}
      font={font}
      color={color}
      lineHeight={lineHeight}
      type={type}
      width={width}
      onClick={onClick}
      variant={variant}
    >
      {isLoading ? <Loading /> : children}
    </StyledButton>
  );
};

export default Button;
