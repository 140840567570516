import { IAlert } from '@pra-vendas-themes/interfaces/IAlert';

import { WithChildren } from 'types/WithChildren';

import { Wrapper } from './styles';

const Alert: WithChildren<IAlert> = ({
  children,
  color = 'primary',
  width,
  textAlign = 'center',
}) => {
  return (
    <Wrapper
      className="Alert"
      color={color}
      width={width}
      textAlign={textAlign}
    >
      {children}
    </Wrapper>
  );
};

export default Alert;
