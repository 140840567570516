import { darken, readableColor } from 'polished';
import styled, { InputColorScheme } from 'styled-components';

export const WrapperInput = styled.div`
  position: relative;
  width: 100%;
  height: 36px;
`;

export const WrapperIcon = styled.span`
  position: absolute !important;
  cursor: pointer;
  font-size: ${(p) => p.theme.font.small};
  line-height: 1em;

  top: 50%;
  transform: translateY(-50%);

  right: ${(p) => p.theme.spacing.medium};
`;

type TStyledInput = {
  color: InputColorScheme;
  hasIcon?: boolean;
};

export const StyledInput = styled.input<TStyledInput>`
  width: 100%;
  height: 100%;
  padding: ${(p) => p.theme.spacing.small} 14px;
  padding-right: ${(p) =>
    p.hasIcon ? p.theme.spacing.xxLarge : p.theme.spacing.medium};
  border-radius: ${(p) => p.theme.borderRadius};
  font-size: ${(p) => p.theme.font.small};
  line-height: ${(p) => p.theme.spacing.medium};
  border: 1px solid ${(p) => p.theme.colors.border};
  background-color: ${(p) => p.theme.input[p.color]};
  color: ${(p) =>
    readableColor(
      darken(0.2, p.theme.input[p.color]),
      p.theme.colors.textDark,
      p.theme.colors.textLight
    )};

  transition: background-color 0.3s, border 0.3s;
  will-change: background-color, border;

  &:disabled {
    background-color: ${(p) => p.theme.input.disabled};
  }

  &:focus {
    border: 1px solid ${(p) => p.theme.colors.primary};
  }
`;
