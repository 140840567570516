import { readableColor, darken, transparentize } from 'polished';
import styled, {
  css,
  ColorScheme,
  ButtonSizesScheme,
  FontSizesScheme,
  SpacingScheme,
} from 'styled-components';

type TStyledButton = {
  color: ColorScheme;
  font: FontSizesScheme;
  size: ButtonSizesScheme;
  lineHeight?: SpacingScheme;
  width?: number;
  transparent?: boolean;
  variant?: 'outlined' | 'default';
};

export const StyledButton = styled.button<TStyledButton>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border: ${(p) =>
    `1px solid ${p.transparent ? 'transparent' : p.theme.colors[p.color]}`};
  border-radius: ${(p) => p.theme.borderRadius};

  font-weight: 500;

  background-color: ${(p) =>
    p.transparent ? 'transparent' : p.theme.colors[p.color]};
  color: ${(p) =>
    p.transparent
      ? p.theme.colors[p.color]
      : readableColor(
          darken(0.22, p.theme.colors[p.color]),
          p.theme.colors.textDark,
          p.theme.colors.textLight
        )};

  font-size: ${(p) => p.theme.font[p.font]};
  padding: ${(p) => p.theme.button[p.size]};

  line-height: ${(p) => p.lineHeight && p.theme.spacing[p.lineHeight]};

  transition: color 0.3s, background-color 0.3s, filter ease-in 0.3s;
  will-change: color, background-color;

  width: ${(p) => (p.width ? `${p.width * 100}% !important` : '')};

  height: 36px;

  ${({ theme, variant, color, transparent }) =>
    variant === 'outlined'
      ? css`
          background: #fff;
          color: ${theme.colors[color]};

          &:not(:disabled):hover {
            filter: brightness(0.93);
          }
        `
      : css`
          &:not(:disabled):hover {
            ${transparent
              ? css`
                  color: ${transparentize(0.15, theme.colors[color])};
                `
              : css`
                  background-color: ${transparentize(
                    0.15,
                    theme.colors[color]
                  )};
                `}
          }
        `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
