import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import { IoSearchOutline } from 'react-icons/io5';

import Input from '@pra-vendas-themes/default/components/Form/Input';
import Button from '@pra-vendas-themes/default/components/Button';

import { maskCep } from 'utils/formMasks';
import { formatCurrency } from 'utils/formatCurrency';

import { IStoreShippingPickup } from 'types/IStoreSettings';
import { IShippingOption } from 'types/IShipping';

import {
  Wrapper,
  WrapperInput,
  WrapperDeliveryType,
  Option,
  Label,
  Value,
} from './styles';

import Alert from '../Alert';

type TDelivery = {
  onClickCalculateShipping: (postalCode: string) => Promise<void>;
  shippingOptions?: IShippingOption[];
  shippingSelected?: IShippingOption;
  selectShipping: (shipping: IShippingOption) => void;
  postalCode?: string;
  manufacturingTime?: number;
  pickup: IStoreShippingPickup | undefined;
};

export const Delivery: FC<TDelivery> = ({
  onClickCalculateShipping,
  shippingOptions,
  shippingSelected,
  selectShipping,
  postalCode,
  manufacturingTime = 0,
  pickup,
}) => {
  const [postalCodeValue, setPostalCodeValue] = useState(postalCode || '');
  const [isLoading, setIsLoading] = useState(false);

  const calculateShipping = useCallback(
    async (_postalCode: string) => {
      if (_postalCode?.length < 10) return;

      try {
        setIsLoading(true);

        await onClickCalculateShipping?.(_postalCode);
      } finally {
        setIsLoading(false);
      }
    },
    [onClickCalculateShipping]
  );

  useEffect(() => {
    calculateShipping(postalCode);
  }, [postalCode]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    await calculateShipping(postalCodeValue);
  }

  async function handleSetPostalCode(
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    setPostalCodeValue(e.target.value);

    await calculateShipping(e.target.value);
  }

  const handleSelectShipping = (shipping: IShippingOption): void => {
    selectShipping(shipping);
  };

  const newShippingOptions = useMemo(() => {
    const pickupOption: IShippingOption = {
      code: 'pickup',
      estimatedTime: pickup?.estimatedTime || 0,
      name: 'Retirada no local',
      price: 0,
    };

    return [
      ...(pickup?.isEnabled ? [pickupOption] : []),
      ...(shippingOptions || []),
    ];
  }, [shippingOptions, pickup]);

  return (
    <Wrapper>
      {manufacturingTime > 0 && (
        <Alert color="warning" width="full" textAlign="left">
          {`O pedido poderá ser despachado em até ${manufacturingTime} dias úteis.`}
        </Alert>
      )}

      <WrapperInput onSubmit={handleSubmit}>
        <Input
          name="zipCode"
          placeholder="Digite seu CEP"
          value={postalCodeValue}
          onChange={maskCep(handleSetPostalCode)}
        />
        <Button isLoading={isLoading} size="xSmall" type="submit">
          <IoSearchOutline />
        </Button>
      </WrapperInput>
      {((shippingOptions && shippingOptions.length > 0) ||
        pickup?.isEnabled) && (
        <WrapperDeliveryType>
          {!isLoading &&
            newShippingOptions?.map((shipping) => (
              <Option
                onClick={() => handleSelectShipping(shipping)}
                key={shipping.name}
              >
                <Label>
                  {shippingSelected?.name === shipping.name ? (
                    <MdRadioButtonChecked />
                  ) : (
                    <MdRadioButtonUnchecked />
                  )}

                  {`${shipping.name} (${shipping.estimatedTime} dia${
                    shipping.estimatedTime === 1 ? '' : 's'
                  })`}
                </Label>
                <Value>{formatCurrency(shipping.price || 0)}</Value>
              </Option>
            ))}
        </WrapperDeliveryType>
      )}
    </Wrapper>
  );
};
