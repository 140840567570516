import { ChangeEvent } from 'react';

import { maskCardExpirationDate, unmask } from './masks';

export const maskCep =
  (changeEvent: (element: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value.replace(/\D/g, '');

    if (newValue.length > 2 && newValue.length <= 5)
      newValue = newValue.replace(/(\d{2})(\d*)/, '$1.$2');

    if (newValue.length > 5)
      newValue = newValue.replace(/(\d{2})(\d{3})(\d{1,3})(.*)/, '$1.$2-$3');

    e.target.value = newValue;
    changeEvent(e);
  };

export const maskPhone =
  (changeEvent: (element: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value.replace(/\D/g, '');
    const { length } = newValue;

    if (length <= 2) newValue = newValue.replace(/(\d{1,2})/, '($1');

    if (length >= 3 && length < 7)
      newValue = newValue.replace(/(\d{2})(\d{1,4})/, '($1) $2');

    if (length >= 7 && length <= 10) {
      newValue = newValue.replace(/(\d{2})(\d{1,4})(\d{1,4})/, '($1) $2-$3');
    }

    if (length >= 11)
      newValue = newValue.replace(
        /(\d{2})(\d{1})(\d{1,4})(\d{1,4})(.*)/,
        '($1) $2 $3-$4'
      );

    e.target.value = newValue;
    changeEvent(e);
  };

export const maskCPF =
  (changeEvent: (element: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value.replace(/\D/g, '');
    const { length } = newValue;

    if (length >= 4 && length < 7)
      newValue = newValue.replace(/(\d{1,3})(\d*)/, '$1.$2');

    if (length >= 7 && length < 10)
      newValue = newValue.replace(/(\d{1,3})(\d{1,3})(\d*)/, '$1.$2.$3');

    if (length >= 10)
      newValue = newValue.replace(
        /(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,2})(.*)/,
        '$1.$2.$3-$4'
      );

    e.target.value = newValue;
    changeEvent(e);
  };

export const maskCardNumber =
  (changeEvent: (element: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value.replace(/\D/g, '');
    const { length } = newValue;

    if (length >= 5 && length < 9)
      newValue = newValue.replace(/(\d{1,4})(\d*)/, '$1 $2');

    if (length >= 9 && length < 13)
      newValue = newValue.replace(/(\d{1,4})(\d{1,4})(\d*)/, '$1 $2 $3');

    if (length >= 13)
      newValue = newValue.replace(
        /(\d{1,4})(\d{1,4})(\d{1,4})(\d{1,4})(.*)/,
        '$1 $2 $3 $4'
      );

    e.target.value = newValue;
    changeEvent(e);
  };

export const maskExpirationDateCard =
  (changeEvent: (element: ChangeEvent<HTMLInputElement>) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = unmask(e.target.value || '');

    e.target.value = maskCardExpirationDate(newValue);
    changeEvent(e);
  };
